@if (settings) {
  <div class="pt-4 pb-4">
    <mat-card>
      <mat-label class="p-2">Käytettävä kamera</mat-label>
      <div class="flex">
        <div class="w-5/6">
          <mat-select [(value)]="selectedDeviceID" [disabled]="!foundDevices">
            @for (device of deviceList; track device) {
              <mat-option [value]="device.deviceId" (click)="change()">{{device.label}}</mat-option>
            }
          </mat-select>
        </div>
        <div class="w-1/6">
          <div class="reload-camera-button">
            <button mat-fab color="warn" (click)="loadDevices()">
              <mat-icon>restart_alt</mat-icon>
            </button>
          </div>
        </div>
        <div *ngIf="error">
            <div>{{error}}</div>
        </div>
        <div *ngIf="!foundDevices">
          <div>Kameraa ei löytynyt.</div>
        </div>
      </div>
    </mat-card>
  </div>
}
